<template>
  <div v-if="data!==null && showLoader===false" class="">
    <div class="bg-white overflow-visible sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">Proceder au paiement CAMWATER</h3>
        <p class="mt-1 max-w-2xl text-sm text-gray-500">Détails relatifs au paiement de CAMWATER </p>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <!-- small screens display -->
          <div class="block sm:hidden sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500"> {{ this.data.customerName }} </dt>
            <dd class="mt-1 text-sm text-gray-900"> Balance: {{ this.data.balance }} </dd>
            <dd class="mt-1 text-sm text-gray-900"> Delai: {{ this.data.deadline }} </dd>
            <dd class="mt-1 text-sm text-gray-900"> Paiement minimal: {{ this.data.minToPay }} </dd>
            <dd class="mt-1 text-sm text-gray-900"> MonthFlow: {{ this.data.monthFlow }} </dd>
            <dd class="mt-1 text-sm text-gray-900"> Penalité: {{ this.data.penalty }} </dd>
            <dd class="mt-1 text-sm text-gray-900"> Impayé: {{ this.data.unpaid }} </dd>
          </div>

          <!-- screens display, excluding small screens -->
          <div class="hidden sm:block sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">Nom</dt>
            <dd class="mt-1 text-sm text-gray-900"> Balance </dd>
            <dd class="mt-1 text-sm text-gray-900"> Delai </dd>
            <dd class="mt-1 text-sm text-gray-900"> Paiement minimal </dd>
            <dd class="mt-1 text-sm text-gray-900"> MonthFlow </dd>
            <dd class="mt-1 text-sm text-gray-900"> Penalité </dd>
            <dd class="mt-1 text-sm text-gray-900"> Impayé </dd>
          </div>
          <div class="hidden sm:block sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">{{ this.data.customerName }}</dt>
            <dd class="mt-1 text-sm text-gray-900">{{ this.data.balance }}</dd>
            <dd class="mt-1 text-sm text-gray-900">{{ this.data.deadline }}</dd>
            <dd class="mt-1 text-sm text-gray-900">{{ this.data.minToPay }}</dd>
            <dd class="mt-1 text-sm text-gray-900">{{ this.data.monthFlow }}</dd>
            <dd class="mt-1 text-sm text-gray-900">{{ this.data.penalty }}</dd>
            <dd class="mt-1 text-sm text-gray-900">{{ this.data.unpaid }}</dd>
          </div>
          <div class="sm:col-span-1">
            <dt class="text-sm font-medium text-gray-500">Numero de compte</dt>
            <dd class="mt-1 text-sm  bg-blue-400 p-1.5 rounded text-white">{{ this.accountNumber }}</dd>
          </div>
          <div class="sm:col-span-2">
            <dt class="text-sm mt-1 font-medium text-gray-500">Montant a payer</dt>
            <div class="flex flex-row justify-between">
              <dd v-bind:class="isOnPromo? 'strike':''" >
                <span class="text-sm text-red-500 ">
                {{creanceMessage}} <p class="text-lg font-black text-gray-900">{{ this.data.penalty + this.data.unpaid }} FCFA</p>
              </span></dd>
              <span class="text-xl  font-extrabold text-blue-500 p-3 -ml-20 " v-if="isOnPromo">{{this.data.promo_amount}} FCFA</span>
            </div>
          </div>
          <div class="flex flex-col gap-4">
            <span class="text-sm mt-1 font-medium text-gray-500">Payer via:</span>
            <div class="flex gap-4 w-full">
              <img @click="makeMomoCall" class="rounded-2xl h-20" src="./../assets/momo.png" alt="Payer via MOMO">
              <img @click="makeOmCall" class="rounded-2xl h-20" src="./../assets/om.png" alt="Payer via OM">
            </div>
          </div>
          <!-- <div class="sm:col-span-2">
            <dt class="text-sm font-medium text-gray-500">Paiement</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <ul role="list" class="border border-gray-200 rounded-md divide-y divide-gray-200">
                <li class="pl-3 pr-4 py-3 flex flex-col space-y-3 items-center justify-between text-sm">
                  <input
                      class="bg-gray-200 ring-1 ring-purple-400 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                      placeholder="Inserer  votre nom" type="text" v-model="params.payeer_name">
                  <input
                      class="bg-gray-200 ring-1 ring-purple-400 appearance-none border-2 border-gray-200 rounded w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                      placeholder="Numero de télephone"  type="number" v-model="params.mobileno">
                </li>
                <li class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                  <button @click="payment" type="button"
                          class="text-white bg-blue-700 w-full hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded text-sm px-5 py-2.5 text-center inline-flex justify-center items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
                    <span>Payer avec NexahPay</span>
                    <svg xmlns="http://www.w3.org/2000/svg" class="w-5 h-5 ml-2 -mr-1" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z" />
                    </svg>
                  </button>
                </li>
              </ul>
            </dd>
          </div> -->
        </dl>
      </div>
    </div>
  </div>
  <loader-component v-if="showLoader" :message="'Chargement en cours'"/>
</template>

<script>
import {PaperClipIcon} from '@heroicons/vue/solid'
import LoaderComponent from "@/components/LoaderComponent";

export default {
  data() {
    return {
      data: null,
      accountNumber: '',
      showLoader : false,
    }
  },
  components: {
    PaperClipIcon,
    LoaderComponent
  },
  computed:{},
  mounted() {
    this.data = JSON.parse(localStorage.getItem('dataLicence'));
    this.accountNumber = localStorage.getItem('accountNumber');
  },
  methods: {
    makeMomoCall() {
      window.location.href = 'tel:*126*2*2#';
    },
    makeOmCall() {
      window.location.href = 'tel:#150*3*3#';
    }
  }
}
</script>

<style>
.strike {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
  color:#404040;
}
</style>