<template>
  <div class="md:p-12 md:mx-6 xs:p-5 xs:mx-6 mt-10">
    <div class="text-center mt-10">
      <h4 class="text-xl font-semibold mt-1 mb-12 pb-1">Paiement de facture CAMWATER</h4>
    </div>
    <form v-if="!showLoader">
      <p class="mb-4">Veillez inserer votre numero de compte</p>
      <div class="mb-4">
        <input
            v-model="license_number"
            type="text"
            class="form-control block w-full mb-12 px-3 py-1.5 text-base font-normal text-gray-700
                            bg-white bg-clip-padding border border-solid border-gray-300 rounded
                            transition ease-in-out m-0 focus:text-gray-700 focus:bg-white
                            focus:border-blue-600 focus:outline-none" min="0"
            id="exampleFormControlInput1"
            placeholder=""
        />
      </div>
      <div class="flex justify-end">
        <button @click="clickSuivant"  type="button" class="text-white bg-blue-600 hover:bg-blue-800 focus:ring-4
                         focus:ring-blue-300 font-medium rounded mb-4 text-sm px-5 py-2.5 text-center
                        inline-flex items-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">
          Valider
          <svg class="ml-2 -mr-1 w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
        </button>
      </div>

    </form>
    <LoaderComponent v-if="showLoader" :message="'Vérification de la licence en cours'"/>
  </div>
</template>

<script>
import LoaderComponent from "@/components/LoaderComponent";
import Detail from "@/views/Detail";
import axios from "axios";
export default {
  name: "InsertOtpComponent",
  data(){
    return{
      showLoader : false,
      license_number : null
    }
  },
  methods: {
    clickSuivant(){
      if (this.license_number){
        this.showLoader = true
        const licence = this.license_number

        axios.get(`https://ms-camwater-preprod.nexah.net/api/v1/accountPayWater/${licence}`)
            .then((response)=>{
              console.log('response is', response)

              localStorage.setItem('dataLicence',JSON.stringify(response.data.data))
              localStorage.setItem('accountNumber',licence)

              this.$emit('suivant',{component:Detail})
            }).catch((error)=>{
          this.showLoader = false
          alert("Votre numéro de compte n'est pas correct")
        })
      }else {
        alert('veillez entrer un numero de compte')
      }

    }
  },
  components:{
    LoaderComponent
  }
}
</script>

<style scoped>

</style>