<template>
  <div class="flex justify-center items-center w-screen h-screen bg-gray-200">
    <div class="lg:w-4/12 mt-20 xs:w-12/12 px-1 mb-1 rounded bg-white shadow-lg">
      <component :data="data" class="mt-2" v-on:suivant="changeComponent" :is="Component"/>
      <LoaderComponent2 v-if="showLoader" />
    </div>
  </div>

</template>

<script>
import InsertNumberComponent from "../components/InsertNumberComponent";
import LoaderComponent2 from "@/components/LoaderComponent2";

export default {
  name: 'Home',
  data() {
    return {
      Component: InsertNumberComponent,
      showLoader : false,
    }
  },
  methods: {
    changeComponent(e) {
      this.Component = e.component
    }
  },
  mounted() {

  },
  components: {
    LoaderComponent2
  }
}
</script>
